// src/components/Home.js
import React from 'react';
import About from './About';

function Home() {
  return (
    <div>
      <About />
    </div>
  );
}

export default Home;
